<template>
  <v-container>
    <BaseScreenHeader title="Get Pro" screenInfo="PRO-001" :showBack="true" />
    <v-card flat class="pb-10 transparent">
      <v-card-actions class="pa-0">
        <v-spacer />
        <v-checkbox
          dark
          :color="!showLimits ? 'progressActive' : 'paper'"
          off-icon="mdi-chevron-down-circle"
          on-icon="mdi-chevron-up-circle"
          v-model="showLimits"
          label="View Free Tier Limits"
          title="View Free Tier Limits"
        />
      </v-card-actions>
      <v-card-text
        v-if="showLimits"
        :class="textSizeXSmall + ' pt-0 silver--text'"
      >
        The free tier has the same functionality as the PRO tier but puts some
        limits in place. See the free tier limitations below.
        <v-list dense class="transparent">
          <v-list-item v-for="v in limits" :key="v.id" class="pl-0 ">
            <v-list-item-icon class="mr-2">
              <v-icon color="silver">{{ iconNumber(v.id - 1) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content :class="textSizeXSmall + ' silver--text'">
              {{ v.text }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions :class="textSizeSmall + ' silver--text pb-0 px-4'">
        <v-divider class="charcoal lighten-2" />
        <span class="mx-2 title silver--text">
          Get <span class="gold--text"> PRO</span>
        </span>
        <v-divider class="charcoal lighten-2" />
      </v-card-actions>
      <v-card
        flat
        class="pa-3 ma-3 transparent"
        v-for="product in products"
        :key="product.id"
      >
        <v-card-title class="progressActive--text pb-0">
          {{ product.name }}
          <v-divider class="ml-2 " />
        </v-card-title>
        <v-img
          :src="require('@/assets/BFitrPro.png')"
          :width="isPhone ? '100px' : '250px'"
          title="BFit Home"
          class="ml-4"
        />
        <v-card-text class="silver--text">
          {{ product.description }}
        </v-card-text>
        <v-card-actions class="caption silver--text pt-0">
          <v-spacer />Type: {{ product.type }}
        </v-card-actions>
        <v-card-title :class="textSize + ' silver--text'">
          <v-divider class="mr-2 charcoal lighten-2 " />
          Pricing
          <v-divider class="ml-2 charcoal lighten-2" />
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="6"
            v-for="variation in product.variations"
            :key="variation.id"
          >
            <v-card flat rounded class="charcoal charcoalTile">
              <v-card-text class="title pb-2 gold--text">
                {{ variation.sku }}
              </v-card-text>
              <v-card-text class="py-0 body-1 silver--text">
                <v-spacer />
                {{ variation.price }} {{ variation.currency }} /
                {{ variation.recurrence }}.
                <span class="progressActive--text" v-if="variation.savings > 0">
                  Save {{ variation.savings }}%.
                </span>
              </v-card-text>
              <v-card-text class="pt-0 silver--text">
                {{ variation.description }}
              </v-card-text>
              <v-card-actions class="pb-0">
                <v-spacer />
                <BaseLinkButton
                  label="Upgrade Now"
                  icon="mdi-arrow-up-circle"
                  text
                  color="progressActive"
                  :href="variation.paylink"
                />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>

    <BaseSnackBar :show="snackbar" :snack="snackText" />
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import limits from '@/json/freelimits.json'
export default {
  mixins: [util],
  name: 'UpgradeToPro',
  components: {},
  data() {
    return {
      limits: limits,
      snackbar: false,
      showLimits: false,
      snackText: '',
      timeout: 2000,
      products: []
    }
  },
  computed: {
    ...appConfig
  },
  beforeMount() {
    this.loadProducts()
  },
  methods: {
    loadProducts() {
      return axios
        .get(this.baseURL + '/store/bfit/products/all', {})
        .then(response => {
          if (response.status == 200) {
            this.products = response.data.data
            this.products.forEach(el => {
              el.variations = JSON.parse(el.variations_json)
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
