<template>
  <v-container>
    <BaseScreenHeader title="Get Pro" screenInfo="PRO-002" :showBack="true" />
    <v-card flat class="pb-10 transparent">
      <v-card-actions class="pa-0">
        <v-spacer />
        <v-checkbox
          dark
          :color="!showLimits ? 'progressActive' : 'paper'"
          off-icon="mdi-chevron-down-circle"
          on-icon="mdi-chevron-up-circle"
          v-model="showLimits"
          label="View Free Tier Limits"
          title="View Free Tier Limits"
        />
      </v-card-actions>
      <v-card-text
        v-if="showLimits"
        :class="textSizeXSmall + ' pa-0 silver--text'"
      >
        The free tier has the same functionality as the PRO tier but puts some
        limits in place. See the free tier limitations below.
        <v-list dense class="transparent">
          <v-list-item v-for="v in limits" :key="v.id" class="pl-0 ">
            <v-list-item-icon class="mr-2">
              <v-icon color="paper">{{ iconNumber(v.id - 1) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content :class="textSizeXSmall + ' paper--text'">
              {{ v.text }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <template v-if="loaded">
        <v-card
          flat
          rounded
          class="transparent"
          v-for="product in products"
          :key="product.id"
        >
          <v-img
            :src="require('@/assets/BFitrPro.png')"
            :width="isPhone ? '125px' : '250px'"
            title="BFitr Pro"
            class="ml-2"
            @load="imgLoaded = true"
          />
          <v-card-text v-if="imgLoaded" class="paper--text mt-3 py-0">
            {{ product.description }}
          </v-card-text>
          <v-card-actions class="caption silver--text pt-0">
            <v-spacer />Type: {{ product.type }}
          </v-card-actions>
          <v-row dense>
            <v-col
              cols="12"
              md="6"
              v-for="variation in product.variations"
              :key="variation.skuId"
            >
              <v-card flat rounded class="charcoal charcoalTileMenu">
                <v-card-text :class="textSize + ' px-2 pb-0 gold--text'">
                  {{ variation.product.description }}
                </v-card-text>
                <v-card-text class="py-0 px-2 body-1 paper--text">
                  <span :class="textSize">
                    {{ variation.product.price }}
                    <span :class="textSizeXSmall + ' silver--text'">
                      {{ variation.product.currencyCode }} /
                      {{ variation.extravars.recurrence }}
                    </span>
                  </span>
                  <v-spacer />
                  <span class="paper--text" v-if="variation.savings > 0">
                    Save {{ variation.savings }}% when compared to monthly!!
                  </span>
                </v-card-text>
                <v-card-text class="pt-0 silver--text">
                  {{ variation.description }}
                </v-card-text>
                <v-card-actions class="pb-0">
                  <v-spacer />
                  <BaseActionButton
                    label="Upgrade Now"
                    icon="mdi-arrow-up-circle"
                    text
                    large
                    color="progressActive"
                    :class="textSize"
                    @clickedThis="upgrade(variation)"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import limits from '@/json/freelimits.json'
import offering from '@/json/offering.json'
export default {
  mixins: [util],
  name: 'UpgradeToProApp',
  components: {},
  data() {
    return {
      limits: limits,
      offering: offering,
      snackbar: false,
      showLimits: false,
      imgLoaded: false,
      products: [],
      storeType: 2,
      loaded: false
    }
  },
  computed: {
    ...appConfig
  },
  beforeMount() {
    if (this.isIOS()) this.storeType = 1
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('viewoffering', this.receiveMessage)
    })
    this.requestOffering()
    this.loadProducts()
  },
  beforeDestroy() {
    window.removeEventListener('viewoffering', this.receiveMessage)
  },
  methods: {
    upgrade(variation) {
      this.requestSubscription(variation)
    },
    receiveMessage(event) {
      if (
        event.detail.message == 'VIEW_OFFERING' &&
        event.detail.key == 'TGqx76ubIp'
      ) {
        this.message = event.detail.messageText
        this.offering = event.detail.data
        this.loadProducts()
      } else null
    },
    loadProducts() {
      return axios
        .get(this.baseURL + '/products/store/' + this.storeType, {})
        .then(response => {
          if (response.status == 200) {
            this.offering.skus.forEach(el => {
              if (el.skuId == 'bfitr_pro_yearly') el.savings = 15
              else el.savings = 0
            })
            this.products = response.data.data
            this.products.forEach(el => {
              el.variations = this.offering.skus
            })

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
