<template>
  <RegisterDialog :token="token" />
</template>

<script>
const RegisterDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/account/Register.vue')
import util from '@/mixins/util.js'

export default {
  name: 'AcceptInvite',
  mixins: [util],
  components: { RegisterDialog },
  beforeMount() {
    this.token = this.$route.params.token
  },
  data() {
    return {
      token: ''
    }
  }
}
</script>
